.container {
  display: flex;
  justify-content: space-between;
}

.tabsContainer {

}

.tabContainer {
  position: relative;
  display: flex;
}

.removeButtonContainer {
  position: absolute;
  right: -3px;
  top: -4px;
}

.loader {
  margin: 10px;
}