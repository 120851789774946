@import '../../styles/sitewide.module';

$submitButtonContainerSpaceFromBottom: 5px;
$submitButtonContainerHeight: 50px;
$submitButtonContainerBorderRadius: 10px;
$submitButtonContainerBorderBoxShadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
$submitButtonContainerSpaceAround: 5px 10px;
$submitButtonContainerBackgroundColor: rgba(255, 255, 255, 0.9);

$formContainerBufferSpaceForSubmitControls: $submitButtonContainerHeight * 2;
$formContainerBgColor: white;
$formContainerSpaceFromNeighbour: 15px;
$formContainerBorderRadius: 10px;
$formContainerBoxShadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

$formButtonContainer: 80px;

$formWrapperInnerWidth: 700px;
$formWrapperInnerSubmitBorder: 10px;
$formWrapperInnerSubmitButtonWidth: $formWrapperInnerWidth - ($formWrapperInnerSubmitBorder * 2);

$formGroupWrapperSpaceAround: 10px;

$formGroupTitleCounterWidth: 40px;
$formGroupTitleCounterBackground: #077398;

$formGroupTitleFontSize: 20px;
$formGroupTitleBackground: rgba(0, 0, 0, 0.05);
$formGroupTitleHeight: 30px;
$formGroupTitleSpaceAround: 10px;
$formGroupTitleSpaceFromCounter: 50px;

$formGroupLabelWidth: 30%;
$formGroupLabelSpaceFromContent: 10px;
$formGroupLabelSpaceFromOwnBorder: 10px;
$formGroupLabelBorderParams: 1px solid #d3d3d357;

.wrapper {
  display: flex;
  justify-content: center;
}

.wrapperInner {
  position: relative;

  width: $formWrapperInnerWidth;

  &.submitButtonWrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    width: $formWrapperInnerSubmitButtonWidth;
  }
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: center;

  background: transparent;
  counter-reset: section;
}

.formControl {
  width: 50%;
  margin-bottom: 5px;
}

.formControlFullWidth {
  width: 100%;
}

.formControlCheckbox {
  .controlBox {
    min-height: 28px;
    border: 1px solid #6C7778;
    border-radius: 5px;
    padding-left: 5px;
    padding-top: 2px;

    background: #ffffff;
  }
}

.formControl:last-child:not(:only-child) {
  padding-left: 5px;
}

.formHint {
  padding: 0;
  margin: 0;
  margin-top: 5px;

  font-size: 10px;
  color: gray;
}

.buttonContainer {
  min-height: $formButtonContainer;
}

.buttonContainerWrapper {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.buttonContainerWrapper button {
  margin-top: 5px;
  margin-left: 5px;
}

.submitButtonContainer {
  display: flex;
  justify-content: center;

  position: fixed;
  bottom: $submitButtonContainerSpaceFromBottom;
  z-index: 10;

  height: $submitButtonContainerHeight;

  background: #f9fafa;

  border-radius: $submitButtonContainerBorderRadius;
  box-shadow: $submitButtonContainerBorderBoxShadow;
  overflow: hidden;

  padding: $submitButtonContainerSpaceAround;
  background: $submitButtonContainerBackgroundColor;
}

.formGroup {
  position: relative;

  margin-top: $formContainerSpaceFromNeighbour;
  border-radius: $formContainerBorderRadius;

  &:first-of-type {
    margin-top: auto;
  }

  background: $formContainerBgColor;
  box-shadow: $formContainerBoxShadow;
  overflow: hidden;
}

.formGroup::before {
  position: absolute;

  content: "";

  border-top: 1px solid #e2dfdf5c;
  width: 100%;
}

.formGroup:first-of-type::before,
.formGroup:last-of-type::before {
  border-top: none;
}

.formGroupWrapper {
  display: flex;
  justify-content: space-between;

  padding: $formGroupWrapperSpaceAround;
}

.formGroupTitle {
  position: relative;
  display: flex;

  height: $formGroupTitleHeight;
  padding: $formGroupTitleSpaceAround;
  padding-left: $formGroupTitleSpaceFromCounter;
  padding-bottom: 0;

  font-size: $formGroupTitleFontSize;
  font-weight: bold;
  background: $formGroupTitleBackground;

  &::before {
    position: absolute;
    top: 0;
    left: 0;

    display: flex;
    justify-content: center;
    align-items: center;

    width: $formGroupTitleCounterWidth;
    height: 100%;

    background: $formGroupTitleCounterBackground;
    color: white;

    counter-increment: section;
    content: counter(section);
  }
}

.formGroupLabel {
  width: $formGroupLabelWidth;
  padding-left: $formGroupLabelSpaceFromOwnBorder;
  margin-left: $formGroupLabelSpaceFromContent;
  border-left: $formGroupLabelBorderParams;
}

.formGroupLabelWrapper {
  width: 85%;
}

.formGroupContent {
  display: flex;
  flex-wrap: wrap;

  width: 70%;
}

.formGroupSection {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.stretchSection {
  width: 100%;
}

.formGroupDescription {
  padding: 0;
  margin: 0;

  font-size: 12px;
  color: gray;
}

.fullSizeContainer,
.fullSizeFormControl {
  min-width: 100%;
}

.controlBox {
  position: relative;
}

.controlBoxHorizontal {
  display: flex;
}

.controlRequiredMark {
  position: absolute;
  right: 3px;
  z-index: 5;

  color: red;
}

.refMarkerList {
  @include reset-list-props;
}

.refMarkerItem {
  display: flex;
}