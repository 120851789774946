@import '../../../styles/sitewide.module';

.weekTitle {
  @include reset-box-model-props;

  font-size: 12px;

  cursor: pointer;
}
.weekContent {
  @include reset-box-model-props;

  font-size: 10px;
  line-height: 1.5;
}

.link {
  color: black;
}

.status {
  padding-left: 5px;
  padding-bottom: 5px;
}


// Meridian overrides
:global {
  div[mdn-calendar-distributed-event] div[mdn-tile-children] {
    padding: 0;
  }
}