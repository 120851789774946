.wrapper {
  display: flex;
  justify-content: center;
}

.wrapperInner {
  position: relative;

  width: 700px;
}

.flexRight {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: center;


  padding: 10px;
}

.formControl {
  width: 50%;
  margin-bottom: 5px;
}

.formControlCheckbox {
  .controlBox {
    min-height: 28px;
    border: 1px solid #6C7778;
    border-radius: 5px;
    padding-left: 5px;
    padding-top: 2px;

    background: #ffffff;
  }
}

.formControl:last-child:not(:only-child) {
  padding-left: 5px;
}

.formHint {
  padding: 0;
  margin: 0;
  margin-top: 5px;

  font-size: 10px;
  color: gray;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  margin: 10px 0;
}

.buttonContainerWrapper {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.buttonContainerWrapper button {
  margin-top: 5px;
  margin-left: 5px;
}

.persistentButtonContainer {
  width: 100%;
  height: 50px;
}

.submitButtonContainer {
  display: flex;
  justify-content: center;

  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;

  padding: 5px;
  width: 100%;
  height: 50px;

  border-top: 1px solid #e2dfdf;
  background: #f9fafa;
}

.formGroup {
  margin-top: 15px;
}

.formGroup::before {
  position: absolute;

  content: "";

  border-top: 1px solid #e2dfdf;
  width: 100%;
}

.formGroup:first-of-type::before,
.formGroup:last-of-type::before {
  border-top: none;
}

.formGroup:first-of-type {
  background: white;
  border-radius: 10px;
  padding: 10px;
}

.formGroupWrapper {
  display: flex;
  justify-content: space-between;

  margin-top: 15px;
}

.formGroupLabel {
  width: 30%;
}

.formGroupLabelWrapper {
  width: 85%;
}

.formGroupContent {
  display: flex;
  flex-wrap: wrap;

  width: 70%;
}

.formGroupSection {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.stretchSection {
  width: 100%;
}

.formGroupDescription {
  padding: 0;
  margin: 0;
  margin-top: 10px;

  font-size: 12px;
  color: gray;
}

.utilizationDescription {
  padding: 0;
  margin: 0;
  margin-top: 10px;

  font-size: 12px;
  color: gray;
}

.fullSizeContainer,
.fullSizeFormControl {
  min-width: 100%;
}

.controlBox {
  position: relative;
}

.controlRequiredMark {
  position: absolute;
  right: 3px;
  z-index: 5;

  color: red;
}

.breadcrumbs {
  padding-top: 20px;
}