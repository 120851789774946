.wrapper {
  display: flex;
  flex-direction: column;

  margin: 20px;
}

.contentContainer {
  margin-top: 20px;
  width: calc(100% - 20px);
  border-radius: 10px;
  border: 10px solid white;

  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  background: white;
}

.warningMessage {
  margin-top: 5px;
}