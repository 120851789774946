.container {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100vw;
  height: 100vh;
}

.wrapper {
  width: 500px;
  height: 200px;
}

.contentContainer {
  display: flex;
  flex-direction: column;
}