$borderRadius: 8px;

.menu {
  z-index: 11;
  position: fixed;
  bottom: 65px;
  right: 25px;
  background-color: white;
  padding-inline-start: 0;
  list-style-type: none;
  border-radius: $borderRadius;
  li:first-of-type {
    border-radius: $borderRadius $borderRadius 0 0;
  }
  li:last-of-type {
    border-radius: 0 0 $borderRadius $borderRadius;
  }
}
.checkWrapper {
  position: absolute;
  left: 4px;
}
.iconTray {
  height: 24px;
  float: right;
  align-self: flex-end;
}
.itemName {
  flex-grow: 1;
  padding-right: 20px;
}
.iconButton {
  padding: 0 2px !important;
}
