@import '../../../../src/styles/sitewide.module';

.controlsContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  padding: 5px;
  border-radius: 10px;
  margin-bottom: 10px;

  background: #f9f9f9;
}

.groupTitle {
  @include reset-box-model-props;

  margin-bottom: 10px;
  border-bottom: 1px solid #ececec;
  padding-bottom: 3px;

  font-weight: 600;
}

.groupBlock {
  padding: 5px;
  border-radius: 10px;
  margin-bottom: 15px;

  background: #f9f9f9;
}

.groupBlockTitle {
  @include reset-box-model-props;

  margin-bottom: 10px;
  border-bottom: 1px solid #ececec;
  padding-bottom: 3px;

  font-size: 18px;
  font-weight: 600;
}

.groupContainer {
  @include reset-list-props;
}

.slotsContainer {
  @include reset-list-props;

  margin-bottom: 10px;

  background-color: white;
  padding: 5px;
  border-radius: 10px;

  border: 1px solid #f5f2f2;
}

.groupItem {
  padding: 5px;
  border-radius: 10px;
  margin-bottom: 5px;

  background-color: #f9f9f9;

  .slotName,
  .placement,
  .scheduleSection,
  .scheduleTitle,
  .groupScheduleList
  {
    @include reset-box-model-props;
  }

  .placement {
    margin-bottom: 5px;
  }

  .slotName {
    margin-bottom: 5px;

    font-weight: bold;
  }
}

.slotBodyContainer {
  font-size: 14px;
}

.groupScheduleList {
  @include reset-list-props;
}
