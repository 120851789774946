@import '../../../src/styles/sitewide.module';

$notificationSpaceFromNext: 5px;
$notificationMinHeight: 30px;
$notificationHorizontalSpaceAround: 10px;
$notificationVerticalSpaceAround: 5px;
$notificationHoverBgColor: #07739836;
$notificationDateTimeFontSize: 12px;
$notificationDateTimeBgColor: gray;
$notificationBorderColor: lightblue;
$notificationBorderSize: 1px;
$notificationBorderRadius: 5px;

$notificationStatusChangeBgColor: #ffd2aa;
$notificationStatusChangeBorderColor: #fd7e13;
$notificationStatusChangeHoverColor: #febd84;

$notificationSymphonyUrlChangeBgColor: #cbbce8;
$notificationSymphonyUrlChangeBorderColor: #7041c1;
$notificationSymphonyUrlChangeHoverColor: #b49dde;

$notificationCommentsReviewChangeBgColor: #f3b5bc;
$notificationCommentsReviewChangeBorderColor: #dd3444;
$notificationCommentsReviewChangeHoverColor: #ee969d;

$mentionBorderColor: #28a744;
$mentionHoverColor: #8ed19e;

$linkFontSize: 14px;

.link {
  font-size: $linkFontSize;
  color: black;

  text-decoration: none;
}

.container {
  display: flex;
}

.checkboxContainer {
  display: flex;
  flex-basis: 2%;

  margin-right: 10px;
}

.notification {
  flex-basis: 98%;

  margin: 0;
  margin-bottom: $notificationSpaceFromNext;
  min-height: $notificationMinHeight;
  padding: $notificationVerticalSpaceAround $notificationHorizontalSpaceAround;

  background-color: $genericNotificationColor;

  border: $notificationBorderSize solid $notificationBorderColor;
  border-radius: $notificationBorderRadius;

  word-break: break-all;

  &:hover {
    background-color: $notificationHoverBgColor;
  }

  .dateTime {
    color: $notificationDateTimeBgColor;
    font-size: $notificationDateTimeFontSize;
  }
}

.notificationStatusChange {
  background-color: $notificationStatusChangeBgColor;
  border-color: $notificationStatusChangeBorderColor;

  &:hover {
    background-color: $notificationStatusChangeHoverColor;
  }
}

.notificationSymphonyUrlChange {
  background-color: $notificationSymphonyUrlChangeBgColor;
  border-color: $notificationSymphonyUrlChangeBorderColor;

  &:hover {
    background-color: $notificationSymphonyUrlChangeHoverColor;
  }
}

.notificationReviewComments {
  background-color: $notificationCommentsReviewChangeBgColor;
  border-color: $notificationCommentsReviewChangeBorderColor;

  &:hover {
    background-color: $notificationCommentsReviewChangeHoverColor;
  }
}

.notificationMention {
  background-color: $mentionNotificationColor;
  border-color: $mentionBorderColor;

  &:hover {
    background-color: $mentionHoverColor;
  }
}

.notificationRead {
  background-color: $readNotificationColor;
}
