@import '../../../src/styles/sitewide.module';

$spaceFromContent: 10px;
$spaceFromNeighbour: 20px;

$containerMinWidth: 700px;
$containerMaxWidth: $containerMinWidth;
$containerMaxHeight: 600px;

$lineSpaceAround: 5px;
$lineBottomBorder: 1px solid lightgray;

$lineHeaderColor: #232F3E;
$lineChangeColor: #d3ffdb;
$lineDeleteColor: #ffd0d3;

$errorsBlockSpaceFromContent: $spaceFromContent;
$errorsBlockSpaceAround: 10px;
$errorsBlockTitleSpaceFromContent: 10px;

$tabsSpaceFromContent: $spaceFromNeighbour;

.tabs {
  margin-bottom: $tabsSpaceFromContent;
}

.changes {
  min-width: $containerMinWidth;
  max-width: $containerMaxWidth;
  max-height: $containerMaxHeight;

  overflow: scroll;

  @include customScrollBar;
}

.listContainer {
  margin-bottom: $spaceFromNeighbour;
}

.errors {
  margin-top: $spaceFromContent;
  padding: $errorsBlockSpaceAround;

  background: #ffd0d3;
}

.errorsTitle {
  @include reset-box-model-props;

  margin-bottom: $errorsBlockTitleSpaceFromContent;

  font-weight: 600;
}

.errorsMessage {}

.list {
  @include reset-box-model-props;

  display: flex;
  flex-direction: column;

  list-style: none;
}

.title {
  @include reset-box-model-props;

  margin-bottom: $spaceFromContent;
}

.line {
  display: flex;

  padding: $lineSpaceAround;
  border-bottom: $lineBottomBorder;
}

.lineHeader {
  background: $lineHeaderColor;
  color: white;
}

.lineAdd,
.lineChange {
  background: $lineChangeColor;
}

.lineDelete {
  background: $lineDeleteColor;
}

.diff {
  width: 33%;
  word-break: break-all;
}

.diff:nth-of-type(2) {
  margin-left: 5px;
}

.lineAdd .diffNew,
.lineChange .diffNew,
.lineChange .diffOld
{
  background: #b0ffbb;
}

.lineDelete .diffOld {
  background: #fdb4bf;
}

.controlsContainer {
  display: flex;
  justify-content: flex-end;
}

.buttonsContainer {
  display: flex;
  justify-items: flex-end;
}