@import '../../../styles/sitewide.module';

.list {
  @include reset-list-props;

  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.item:not(:first-of-type) {
  margin-left: 5px;
}