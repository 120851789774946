@import '../../../src/styles/sitewide.module';

$spaceFromContent: 10px;

.amContainer {
  position: relative;

  margin-top: 10px;
  padding: 15px;

  background: white;
}

.amButtons {
  display: flex;
  flex-direction: column;

  margin-top: 5px;

  button {
    margin-top: 10px;
  }
}

.amControls {
  margin: 20px 0;
}

.amList {
  @include reset-list-props;

  display: flex;
}

.amItem {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &:not(:first-of-type) {
    margin-left: 10px;
  }
}

.amReportList {
  @include reset-list-props;
}


.loaderText {
  color: white;
  font-size: 30px;
}

.importWarningMessage {
  margin-top: $spaceFromContent;
}