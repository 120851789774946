@import '../../../src/styles/sitewide.module';

$overlayContainerIndex: 800;
$overlayContainerHeight: 40px;

$announcementsContainerMaxHeight: 50vh;

$announcementContainerColor: #000000;
$announcementContainerFontSize: 14px;
$announcementContainerSpaceAround: 10px;
$announcementContainerBottomBorder: 1px solid rgba(210, 202, 2020, 0.2);
$announcementContainerHoveredOverColor: #FFFFCC;

$messageContainerWidth: 95%;
$messageContainerSpaceFromCloseButton: 20px;

$announcementSpaceFromNextElement: 5px;

$closeButtonWidth: 40px;
$closeButtonHeight: 30px;
$closeButtonFontSize: 20px;
$closeButtonFontSizeHover: 30px;
$closeButtonFontWeight: 700;
$closeButtonOpacity: 0.5;
$closeButtonOpacityHover: 0.75;

$backgroundColor: #FFFFCC;
$textColor: rgba(255, 255, 255, 1);
$textShadowColor: $textColor;

.container {
  position: relative;

  height: $overlayContainerHeight;

  color: $textColor;
  background: $backgroundColor;

  overflow: hidden;

  &:hover {
    z-index: $overlayContainerIndex;

    height: initial;

    overflow: visible;
  }
}

.announcements {
  @include reset-box-model-props;
  @include hideScrollbar;

  max-height: $announcementsContainerMaxHeight;

  overflow: scroll;
}

.announcementContainer {
  padding: $announcementContainerSpaceAround;
  border-bottom: $announcementContainerBottomBorder;

  font-size: $announcementContainerFontSize;
  color: $announcementContainerColor;

  &:hover {
    background-color: $announcementContainerHoveredOverColor;
  }
}

.messageContainer {
  @include reset-box-model-props;

  padding-right: $messageContainerSpaceFromCloseButton;
}

.announcement {
  @include reset-box-model-props;

  p,
  h1,
  h2,
  h3
  {
    @include reset-box-model-props;

    margin-bottom: $announcementSpaceFromNextElement;
  }
}