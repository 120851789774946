@import '../../../src/styles/sitewide.module';

$wrapperSpaceFromNavigationBar: 50px;
$formWrapperInnerWidth: 700px;
$formWrapperInnerMaxHeight: 80vh;
$formWrapperInnerBorderRadius: 10px;
$formWrapperInnerPadding: 20px;

$overlayZIndex: 10;

$closeButtonSpaceFromWrapper: 4px;

.overlay {
  position: fixed;
  z-index: $overlayZIndex;

  width: 100%;
  height: 100%;

  background: rgba(0, 0, 0, 0.7);
}

.closeButton {
  position: absolute;
  top: $closeButtonSpaceFromWrapper;
  right: 0;
}

.wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;

  min-width: 900px;
  max-width: 90vw;

  padding-top: $wrapperSpaceFromNavigationBar;

  position: fixed;
  left: 50%;
  z-index: 10;

  transform: translateX(-50%);

  @include customScrollBar;
}

.wrapperInner {
  position: relative;

  max-height: $formWrapperInnerMaxHeight;
  overflow-x: hidden;
  overflow-y: scroll;

  background: rgba(255,255,255,0.8);
  border-radius: $formWrapperInnerBorderRadius;
  padding: $formWrapperInnerPadding;
}

.wrapperAudit {
  position: relative;

  &.wrapper {
    overflow: hidden;
    background: white;
    padding: 20px;
    padding-top: 35px;

    @include shadow;
  }

  .wrapperInner {
    padding-left: 0;
    overflow: scroll;
  }
}

.wrapperAuditExtended {
  margin: 0 20px;
  max-height: 65vh;

  &.wrapper {
    position: fixed;
    left: 0;
    right: 0;
  }

  .wrapperInner {
    width: 100%;
  }
}

.buttonAuditLogSwitchMode {
  display: none;

  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;

  padding: 5px;
}

.auditLogContainer {
  overflow-x: scroll;
}