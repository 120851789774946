@import '../../../src/styles/sitewide.module';

$spaceFromHeader: 10px;
$spaceFromHeading: 10px;
$wrapperWidth: 800px;

$searchBasisWidth: 100%;
$searchMinHeight: 50px;
$searchSpaceAroundText: 10px;
$searchContainerSpaceFromBottomNeighbour: 7px;
$searchBorderRadius: 10px;
$searchBgColor: #ffffff;
$searchShadow: rgba(0,0,0,0.11) 0 0.3px 0.9px, rgba(0,0,0,0.11) 0 1.6px 3.6px;

.container {
  display: flex;
  justify-content: center;

  margin-top: $spaceFromHeader;
}

.wrapper {
  width: $wrapperWidth;
}

.pageName {
  text-align: center;
}

.searchContainer {
  display: flex;
  justify-content: center;

  flex-basis: $searchBasisWidth;
  min-height: $searchMinHeight;
  padding: $searchSpaceAroundText;
  margin-bottom: $searchContainerSpaceFromBottomNeighbour;
  border-radius: $searchBorderRadius;

  background-color: $searchBgColor;

  box-shadow: $searchShadow;
}

.contentContainer {
  flex-wrap: wrap;

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  margin-top: $spaceFromHeading;
}