.iframeWrapper {
  position: fixed;
  z-index: 2000;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  background-color: white;
}
.iframeCloseButton {
  position: fixed;
  right: 18px;
  top: 0;
  transform: scale(0.7);
}
.iframe {
  width: 100%;
  height: 100%;
}
.iframeContainer {
  position: fixed;
  top: 40px;
  bottom: 0;
  left: 0;
  right: 0;
}