@import '../../../src/styles/sitewide.module';

.closeButtonContainer {
  position: absolute;
  top: 5px;
  right: 5px;
}

.headerContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.generalSummary {
  display: flex;
  justify-content: space-around;
}

.generalSummaryContainer {
  display: flex;
  flex-direction: column;
}

.title,
.vendorName {
  @include reset-box-model-props;
}

.dollarsSummaryContainer {
  display: flex;
  justify-content: space-around;
}

.warningContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.warningMessage {
  font-size: 20px;
  font-weight: bold;
  color: red;
  border: 5px solid red;
  padding: 10px;
}