$default-bg-color: white;
$color-border-status-warning: lightgray;
$color-text-form-secondary: gray;
$color-background-control-disabled: purple;

$yellow: #f3e396;
$green: #ddf8d3;
$grey: #d5dbdb;

.calendarTableWrapper {
  overflow-y: hidden;
  overflow-x: auto;
  scrollbar-color: $color-border-status-warning;
  height: fit-content;
  border-radius: 0px 0px 5px 5px;
  border-right: 0.5px solid $color-border-status-warning;
  border-bottom: 0.5px solid $color-border-status-warning;

  .table th {
    position: relative;
  }

  .calendarTableHeaderCell {
    div {
      width: 70px;
      margin: auto;
    }
  }

  .resizer {
    position: absolute;
    top: 0;
    right: 0;
    width: 5px;
    cursor: col-resize;
    user-select: none;
    height: 100%;
    margin-top: -1px;
    border-right: 2px solid $color-border-status-warning;
  }

  .calendarTable {
    width: 100%;
    min-width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    font-size: 12px;
    overflow: auto;
    height: 100%;
    scrollbar-color: $color-border-status-warning;
    border-radius: 0px 0px 5px 5px;
  }

  thead {
    background: $default-bg-color;
  }

  tbody {
    background: $default-bg-color;

    tr:last-of-type td:first-of-type {
      border-bottom-left-radius: 5px;
    }

    tr:last-of-type td:last-of-type {
      border-bottom-right-radius: 5px;
    }
  }

  table,
  th,
  td {
    border: 0.5px solid $color-border-status-warning;
  }

  th {
    font-weight: normal;
    padding: 10px 6px;
    position: sticky;
    top: 0;
    background: $default-bg-color;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);

    &.slotNameHeader,
    {
      padding-left: 40px;
      position: sticky;
      left: 0;
      background: $default-bg-color;
      z-index: 11;
    }
  }

  td {
    min-width: 72px;
    word-break: break-all;
    position: relative;

    &.slotNameColumn,
    {
      text-align: left;
      min-width: 163px;
      position: sticky;
      left: 0;
      background: $default-bg-color;
      padding: 4px 12px;
      word-break: normal;
      transition: all 0.5s;
      z-index: 10;

      &.slotNameColumnExpand {
        vertical-align: top;
        padding: 10px 12px;
      }

      &.slotNameColumnCollapse {
        height: fit-content;
      }

      .slotNameColumnSection {
        cursor: pointer;
        display: flex;
      }

      .slotDescriptionContainer {
        margin-left: 5px;
      }

      .slotPlacementCount {
        color: gray;
      }
    }
  }

  .record {
    box-sizing: border-box;

    display: flex;
    align-items: center;
    height: 32px;
    padding: 5px;
    padding-top: 3.5px;
    outline: 1px solid $grey;

    font-size: 14px;
    line-height: 22px;

    position: absolute;
    left: 0;
    z-index: 9;

    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    cursor: pointer;
  }

  .sov {
    margin-right: 5px;
  }
}