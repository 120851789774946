@import '../../styles/sitewide.module';

$wrapperWidth: 900px;

.addNewAnnouncementButton {
  margin: 20px 0;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 14px;
}

.wrapper {
  width: $wrapperWidth;
}

.breadcrumbs {
  padding-top: 20px;
}

.heading {
  display: flex;
  justify-content: center;
}

.controls {
  width: 100px;
}

.userInputArea {
  @include reset-box-model-props;

  font-size: 14px;

  p {
    @include reset-box-model-props;
  }
}

.option {
  font-size: 14px;
}