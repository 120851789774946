.calendarContainer {
  margin-top: 20px;

  > div {
    height: initial;
  }

  // Meridian's overrides
  :global {
    span[aria-current="date"] {
      font-size: 30px;
      background-color: #b9e8f7;
    }
  }
}