@import '../../../src/styles/sitewide.module';

$wrapperSpaceFromNavigationBar: 50px;
$formWrapperInnerWidth: 700px;
$formWrapperInnerMaxHeight: 80vh;
$formWrapperInnerBorderRadius: 10px;
$formWrapperInnerPadding: 20px;

$overlayZIndex: 10;

$closeButtonSpaceFromWrapper: 4px;

.overlay {
  position: fixed;
  z-index: $overlayZIndex;

  width: 100%;
  height: 100%;

  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(8px);
}

.closeButton {
  position: absolute;
  top: $closeButtonSpaceFromWrapper;
  right: 0;
}

.wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;

  padding-top: $wrapperSpaceFromNavigationBar;

  position: fixed;
  left: 50%;
  z-index: 10;

  transform: translateX(-50%);

  @include customScrollBar;
}

.wrapperInner {
  position: relative;

  width: $formWrapperInnerWidth;
  max-height: $formWrapperInnerMaxHeight;
  overflow: scroll;

  background: rgba(255,255,255,0.8);
  border-radius: $formWrapperInnerBorderRadius;
  padding: $formWrapperInnerPadding;
}

