@import '../../../../../src/styles/sitewide.module';

.filtersContainer {
  display: flex;
  justify-content: space-between;

  margin-bottom: 10px;

  .filtersButton {
    margin: 10px 0;
  }

  .filtersDescription {
    font-size: 14px;
  }

  .activeFiltersTitle {
    @include reset-box-model-props;

    margin-bottom: 5px;
  }

  .activeFiltersList {
    @include reset-list-props;

    .filter {
      @include reset-box-model-props;
    }
  }
}