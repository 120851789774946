.overlay {
  position: fixed;
  z-index: 999;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  background: #000000ad;
}

.loaderContainer {
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
}

.loaderText {
  font-size: 20px;
  color: white;
}