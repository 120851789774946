@import '../../../../src/styles/sitewide.module';

.dates {
  @include reset-box-model-props;

  margin-bottom: 5px;

  position: relative;

  font-size: 12px;
  color: gray;
}

.message {
  @include reset-box-model-props;

  font-size: 14px;

  p {
    @include reset-box-model-props;
  }
}

.badge {
  position: absolute;
  top: 0;
  right: 0;

  border: 1px solid transparent;
  border-radius: 10px;
  padding: 0 5px;

  background: #B9E8F7;
  font-size: 12px;
}