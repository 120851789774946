$grey: #d5dbdb;
.approved {
  background-color: darkgreen;
  color: white;
  .link {
    color: white;
  }
}

.canceled {
  background-color: #f0f1f2;
}

.hold {
  background-color: #c4e2ff;
}

.copyRevisionNeeded {
  background-color: #c4e2ff;
}

.inputsComplete {
  background-color: #dcf3a0;
}

.missingInputs {
  background-color: orange;
}

.newEdits {
  background-color: $grey;
}