.errorMessageSection {
  margin: 12px 0;
}
.errorMessageTitle {
  font-weight: bold;
  margin: 4px 0;
}
.errorMessageWithError {
  margin: 4px 0;
  color: red;
}
.errorMessageWithoutError {
  margin: 4px 0;
  color: green;
}
.inputTitle {
  margin: 4px 0;
}
.inputGroup {
  width: 400px;
}
.warning {
  width: 400px;
  margin-bottom: 20px;
  color: red;
}
.spinnerContainer {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border: 1px solid #ccc;
  z-index: 50;
  border-radius: 4px;
  padding: 8px;
  text-align: center;
}
.spinnerWrapper {
  padding: 8px;
}

.hoverContent {
  visibility: hidden;
  width: 400px;
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text */
  position: absolute;
  bottom: 125%;
  left: 50%;
  margin-left: -200px;

  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

/* Tooltip arrow */
.hoverContent::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.hoverContainer {
  position: relative;
  display: inline-block;
}

.hoverContainer:hover .hoverContent {
  visibility: visible;
  opacity: 1;
}

.modalContainer {
  overflow: visible;
}