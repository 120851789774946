@import '../../../../src/styles/sitewide.module';

.title {
  @include reset-box-model-props;

  margin-bottom: 10px;

  font-weight: normal;

  > span {
    font-weight: bolder;
  }
}