// TODO: constants
@import '../../../src/styles/sitewide.module';

$containerVerticalSpacing: 20px;
$containerHorizontalSpacing: 10px;

$wrapperWidth: 600px;

$innerContainerBorderRadius: 10px;
$innerContainerSpaceAround: 10px;
$innerContainerBoxShadow: 5px 11px 27px -10px rgba(0, 0, 0, 0.73);
$innerContainerHorizontalCollapseSpace: 1px;
$innerContainerBgColor: #ffffff;

$headingHorizontalSpaceAround: 10px;

$notificationSpaceFromNext: 5px;
$notificationMinHeight: 30px;
$notificationHorizontalSpaceAround: 10px;
$notificationVerticalSpaceAround: 5px;

.container {
  display: flex;
  justify-content: center;

  margin: $containerVerticalSpacing $containerHorizontalSpacing;
}

.wrapper {
  width: $wrapperWidth;
}

.commentContainer {
  display: flex;
}

.contentWrapper {
  display: flex;
  width: 80%;
}

.photoContainer {

}

.photoWrapper {
  min-height: 50px;
  min-width: 50px;
}

.mainContainer {
  display: flex;
  flex-direction: column;

  margin: 0 10px;

  .messageContainer {
    background: #eff2f5;
    border-radius: 10px;
    padding: 10px;

    white-space: pre-line;
  }

  .controlsContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: 10px;
  }

  .username {
    display: flex;

    margin: 0;
    margin-bottom: 5px;

    font-weight: bold;
  }

  .message {
    word-break: break-all;
  }

  .dateTime,
  .replyBack {
    font-size: 12px;
  }

  // Meridian's Button font size override
  .replyBack button {
    font-size: 12px;
  }
}

.actionsContainer {

}

.ownComment {
  &.commentContainer {
    justify-content: flex-end;
  }

  .contentWrapper {
    display: flex;
    flex-direction: row-reverse;
  }

  .messageContainer {
    background: #077398;
    color: white;
  }
}