@import '../../../src/styles/sitewide.module';

$containerWidth: 700px;
$spaceFromNeighbour: 10px;
$cardListContainerSpaceFromTitle: $spaceFromNeighbour;
$paginationSpaceFromContent: $spaceFromNeighbour;

$buttonAddNewSpaceFromNeighbour: $spaceFromNeighbour;

.container {
  margin: 0 auto;

  // Meridian's overwrite to prevent data overflow in tables
  td {
    word-break: break-all;
  }
}

.headerContainer {
  display: flex;
  align-items: center;

  margin-top: $spaceFromNeighbour;
}

.buttonAddNew {
  margin-left: $buttonAddNewSpaceFromNeighbour;
}

.cardListContainer {
  margin-top: $cardListContainerSpaceFromTitle;

  overflow-y: scroll;

  td {
    white-space: nowrap;
  }
}

.pagination {
  margin-top: $paginationSpaceFromContent;
}

.bulkImporter {
  overflow: scroll;
}