@import '../../../../../src/styles/sitewide.module';

.chartContainer {
  display: flex;
  flex-flow: row nowrap;

  margin-bottom: 15px;
}

.chartItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around ;

  width: 33%;
}

.label {
  font-size: 13px;
}

.orange {
  color: $dashboardWarningColor;
}

.green {
  color: $dashboardSuccessColor;
}

.blue {
  color: $dashboardInfoColor;
}

.red {
  color: $dashboardErrorColor;
}