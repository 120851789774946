@import '../../../../src/styles/sitewide.module';

$padding: 5px;
$tableHeaderZIndex: 10;
$columnHeaderZIndex: 8;

.table {
  border-collapse: collapse;
  position: relative;

  min-width: 100%;
}

.table .rowHeader {
  background-color: #eee;
  border: 1px solid lightgray;
}

.table tr:hover {
  background: rgba(0, 0, 0, 0.1);
}

.tableHeader {
  position: sticky;
  top: -2px;
  z-index: $tableHeaderZIndex;
}

.columnHeader {
  position: sticky;
  left: 0;
  z-index: $columnHeaderZIndex;

  border: 1px solid #ccc;
  border-right-style: hidden;
  width: 200px;
  min-width: 200px;

  text-align: left;
  background: white;

  .cell {
    border-right: 1px solid lightgray;
  }
}

.cellContainer {
  position: relative;
  padding: 0;
  border: 1px solid #ccc;
  min-width: 100px;
  text-align: center;
}

.cell {
  position: relative;
  padding: $padding;
  padding-right: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #111;
}

.tooltip {
  position: absolute;
  top: 0;
  right: 10px;
}

.yellowCellContainer {
  position: relative;
  padding: 0;
  border: 1px solid #ccc;
  max-width: 400px;
  text-align: center;
  background-color: #ffcc00;
}

.redCellContainer {
  position: relative;
  padding: 0;
  border: 1px solid #ccc;
  max-width: 400px;
  text-align: center;
  background-color: #FFBABA;
}

.utilizationContainer {
  display: flex;
  flex-direction: column;

  .slots {
    @include customScrollBar;

    overflow: scroll;
    max-height: 700px;
  }

  .slots:not(:first-of-type) {
    margin-top: 10px;
  }
}
