.circularChart {
  display: block;
  margin: 10px auto;
  max-width: 80%;
  max-height: 250px;
}

.circleBackground {
  fill: none;
  stroke: #eee;
  stroke-width: 3.8;
}

.circle {
  fill: none;
  stroke-width: 2.8;
  stroke-linecap: round;
  animation: progress 1s ease-out forwards;
}

.circularChart {
  &.orange {
    .circle {
      stroke: #ff9f00;
    }
  }

  &.green {
    .circle {
      stroke: #4CC790;
    }
  }

  &.blue {
    .circle {
      stroke: #3c9ee5;
    }
  }

  &.red {
    .circle {
      stroke: #e52012;
    }
  }
}

.percentage {
  fill: #666;
  font-family: sans-serif;
  font-size: 7px;
  text-anchor: middle;
}

@keyframes progress {
  0% {
    stroke-dasharray: 0 100;
  }
}