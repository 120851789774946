.menuItem {
  border: 1px solid black;
  padding: 4px 8px;
  cursor: pointer;
  margin-top: -1px;
  height: 24px;
  display: flex;
  align-items: center;
  &:hover {
    background-color: #eee;
  }
}
.menuItemContent {
  outline: none;
  display: flex;
  align-items: center;
  width: 100%;
  min-width: 140px;
}
.active {
  background-color: #b9e8f7; /* TODO: finalize color option */
}