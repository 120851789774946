$titleContainerBorderRadius: 10px;
$titleContainerShadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
$titleContainerSpaceFromContent: 10px;
$titleContainerMinHeight: 70px;

$tabsContainerBorderRadius: $titleContainerBorderRadius;

$lastUpdatedLinkSpaceFromBottom: 2px;
$lastUpdatedLinkSpaceFromRight: 10px;
$lastUpdatedLinkFontSize: 13px;
$lastUpdatedLinkColor: gray;

.container {
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: $titleContainerBorderRadius;
  margin-bottom: $titleContainerSpaceFromContent;
  min-height: $titleContainerMinHeight;

  background: white;
  box-shadow: $titleContainerShadow;
  overflow: hidden;
}

.tabsContainer {
  position: relative;
  z-index: 2;

  margin-bottom: 20px;
  border-top-right-radius: $tabsContainerBorderRadius;
  border-top-left-radius: $tabsContainerBorderRadius;

  background: white;

  overflow-y: scroll;
}

.headerTitle {
  margin: $titleContainerSpaceFromContent;
}

.lastUpdatedLink {
  position: absolute;
  bottom: $lastUpdatedLinkSpaceFromBottom;
  right: $lastUpdatedLinkSpaceFromRight;

  font-size: $lastUpdatedLinkFontSize;
  color: $lastUpdatedLinkColor;
}