$radius: 15px;

.outer {
  position: absolute;
  bottom: 10px;
  right: 10px;
  z-index: 10;
  border-radius: ($radius + 2);
  border: ($radius + 2) solid #111;
  cursor: pointer;
  outline: none;
}
.gridViewFilterControl {
  position: absolute;
  top: -$radius;
  right: -$radius;
  border-radius: $radius;
  border: $radius solid #eee;
}
.inner {
  position: absolute;
}
.iconWrapper {
  position: absolute;
  top: -12px;
  right: -12px;
}
.badgeWrapper {
  position: absolute;
  top: -25px;
  right: -25px;
}
.backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0,0,0,0.5);
  z-index: 10;
}
