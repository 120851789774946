@import '../../../../src/styles/sitewide.module';

.searchPanel {
  display: flex;

  justify-content: center;
  align-items: center;
}

.wrapper {
  display: flex;
}

.toggleContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  margin-bottom: 5px;
  margin-right: 10px;

  .toggleDescription {
    @include reset-box-model-props;

    margin-top: 8px;
    margin-bottom: 10px;

    font-size: 14px;
    color: gray;
  }

  .toggleButtonWrapper {
    @include reset-box-model-props;

    margin-bottom: 5px;
  }

  .toggleModeName {
    font-size: 15px;
  }

  .toggleHint {
    @include reset-box-model-props;

    width: 200px;

    font-size: 12px;
    color: gray;
  }
}

.searchBar {
  display: flex;

  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;

  .searchButtonContainer {
    margin-left: 10px;
  }
}

.dateTimePickerContainer {
  display: flex;
  flex-direction: column;

  .datePicker {
    margin-bottom: 10px;
  }

  .timePicker {
    display: flex;
    justify-content: space-between;

    .startDate,
    .endDate {
      flex-basis: 49%;
      border-radius: 10px;

      /* Override for default GridView's time input style */
      & input {
        border-radius: 5px;
        border-color: gray;
      }
    }

  }
}

.dateRange {
  display: flex;
  justify-content: center;

  border-radius: 10px;
  padding: 10px;
  background: #f9f9f9;

  .highlightedDateRange {
    font-weight: bold;

    padding: 0 5px;
  }
}