@import '../../../src/styles/sitewide.module';

$wrapperWidth: 100%;
$wrapperMinWidth: 500px;

$innerContainerBorderRadius: 10px;
$innerContainerSpaceAround: 10px;
$innerContainerBoxShadow: 5px 11px 27px -10px rgba(0,0,0,0.73);
$innerContainerHorizontalCollapseSpace: 1px;
$innerContainerBgColor: #ffffff;

$modalHeaderTextSpaceFromContent: 15px;

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.wrapper {
  width: $wrapperWidth;
  min-width: $wrapperMinWidth;
}

.innerContainer {
  border-radius: $innerContainerBorderRadius;
  padding: $innerContainerSpaceAround;
  box-shadow: $innerContainerBoxShadow;
  /* Prevents .innerContainer and .wrapper from collapsing on vertical margin */
  border-top: transparent $innerContainerHorizontalCollapseSpace solid;
  border-bottom: transparent $innerContainerHorizontalCollapseSpace solid;

  background: $innerContainerBgColor;
}

.list {
  list-style: none;
  word-wrap: break-word;

  @include reset-box-model-props;
  @include hideScrollbar;

  .listItem {
    margin-bottom: 10px;
  }
}

.chatScreen {
  width: 100%;
  min-height: 500px;
  max-height: 200px;
  overflow: scroll;

  background: white;

  @include hideScrollbar;
}

.commentsWrapper {
  padding-top: 10px;

  .lengthCounterMessage {
    display: flex;
    margin: 10px 0 10px 5px;
    color: gray;
    font-size: 12px;
  }
}

.controlsContainer {
  position: relative;

  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 10px;
  border-top: 1px solid #f3efef;

  padding: 10px 0;
}

.controls {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;

  min-width: 40%;
}

.controlsSystem {
  justify-content: flex-start;
}

.control {
  min-width: 60px;
}

.controlLengthCounter {
  display: flex;
  align-items: center;

  color: gray;
  font-size: 12px;
}

// TODO: same min height as parent container
.noCommentsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 500px;
}

.noComments {
  color: gray;
}

.postingLoaderContainer {
  position: absolute;
  left: 0;

  display: flex;
  align-items: center;

  background: white;
  min-width: 200px;
  min-height: 50px;

  .postingText {
    margin-left: 10px;

    color: gray;
  }
}

.modalHeaderText {
  margin-bottom: $modalHeaderTextSpaceFromContent;
  max-width: 350px;
  max-height: 50px;

  overflow: scroll;
  word-break: break-all;

  @include hideScrollbar;
}