@import '../../../../src/styles/sitewide.module';

.settingsContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 500px;
}

.buttonContainer {
  display: flex;
  justify-content: center;
  align-items: center;

  margin-top: 10px;

  button:not(:first-of-type) {
    margin-left: 5px;
  }
}

.list {
  @include reset-list-props;
  @include hideScrollbar;

  margin: 10px 0;

  overflow-y: scroll;
  max-height: 600px;
}