.filterColumn {
  width: 185px;
  display: inline-block;
}
.tripleFilterColumn {
  width: 630px;
  display: inline-block;
}
.sortColumn {
  width: 285px;
  display: inline-block;
}
.row {
  margin-bottom: 12px;
  padding: 8px;
  display: inline-block;
}
.childRow {
  margin-top: 8px;
  border-radius: 4px;
  box-shadow: 4px 4px 5px 0px rgba(102,102,102,0.3);
}
.oddGroup {
  background-color: #eee;
}
.evenGroup {
  background-color: #d9d9dd;
}
.actionButton {
  width: 185px;
}
.spacer {
  margin-bottom: 16px;
}
.groupType {
  font-size: 12px;
  cursor: pointer;
}
.wrapper {
  width: 100%;
}
.actionRow {
  margin-left: -4px;
  display: flex;
}
.actionRowItem {
  padding: 4px;
  width: 185px;
  display: inline-block;
}