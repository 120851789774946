$activeColor: #fff;
$disabledFont: #666;
$hoverColor: #ddd;
$padding: 8px;
$borderWidth: 1px;
$activeBorderColor: blue;
$borderColor: #ccc;
$hoverBorderColor: #0000cc;

.cell {
  position: relative;
  padding: $padding;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #111;
}
.actionButton {
  width: 44px
}
.clickable {
  cursor: pointer;
}
.clickableHovered {
  background-color: $hoverColor;
  border: $borderWidth solid $hoverBorderColor;
  padding: $padding - $borderWidth;
}
.active {
  padding: 0;
  width: 100%;
}
.left {
  text-align: left;
}
.right {
  text-align: right;
}
.center {
  text-align: center;
}
.inactive {

}
.preview {
  background-color: #a585851f;
}

.disabled {
  background-image:
  linear-gradient(
                  to bottom right,
                  transparent calc(50% - 1px),
                  rgb(128 128 128 / 13%),
                  transparent calc(50% + 1px)
  ),
  linear-gradient(
                  to bottom left,
                  transparent calc(50% - 1px),
                  rgb(128 128 128 / 13%),
                  transparent calc(50% + 1px)
  );
  background-color: #8080801f;
}
.activeInput {
  border: $borderWidth solid $activeBorderColor;
  padding: $padding - $borderWidth;
  display: block;
  width: calc(100% - #{$padding * 2});
  font-size: 1rem;
  background-color: $activeColor;
}
.disabledInput {
  color: $disabledFont;
  border: $borderWidth solid $activeBorderColor;
  padding: $padding - $borderWidth;
  display: block;
  width: calc(100% - #{$padding * 2});
  font-size: 1rem;
  background-color: $activeColor;
}
.activeInput:focus {
  outline: none;
}
.cellContainer {
  position: relative;
  padding: 0;
  border-right: $borderWidth solid $borderColor;
  border-bottom: $borderWidth solid $borderColor;
  max-width: 400px;
  background-color: inherit;
}
.tableHeader {
  background-color: #F9FAFA;
  position: sticky;
  top: 0;
  z-index: 1;
  border-bottom: $borderWidth solid $borderColor;
  &.sticky {
    z-index: 9;
  }
}
.errorIcon {
  position: absolute;
  top: 0;
  right: 0;
  border-top: 3px solid red;
  border-right: 3px solid red;
  border-left: 3px solid transparent;
  border-bottom: 3px solid transparent;
}
.errorText {
  background-color: white;
  position: absolute;
  padding: 4px;
  top: 32px;
  right: 0;
  border: 1px solid #777;
  z-index: 10;
}
.sticky {
  z-index: 8;
  position: sticky;
  left: 0;
}
.header {
  border-bottom: $borderWidth solid $borderColor;
}
.floatingLink {
  position: absolute;
  right: 1px;
  top: 1px;
  padding: 2px;
  z-index: 10;
  background-color: $hoverColor;
}
.add {
  background-color: #28a745;
}
.remove {
  background-color: #dc3545;
}
.update {
  background-color: #ffc107;
}