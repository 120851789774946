.container {
  position: relative;

  display: flex;

  padding: 10px;
  border-radius: 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

  background: white;
}

.controlButtonsContainer {
  display: flex;
  margin-left: 10px;

  & .control {
    flex-basis: 100px;

    margin-right: 5px;
  }

  & .controlDateTimePicker {
    flex-basis: 380px;
  }

  & .controlButtons {
    display: flex;

    margin-top: 5px;
  }

  & .showButton {
    margin-left: 5px;
  }
}

.groupContainer {
  display: flex;
  flex-direction: column;
}

.datePicker {
  margin-bottom: 5px;
}

.timePicker {
  display: flex;
  justify-content: space-between;

  .startDate,
  .endDate {
    flex-basis: 49%;
    border-radius: 10px;

    /* Override for default GridView's time input style */
    & input {
      border-radius: 5px;
      border-color: gray;
    }
  }
}

.filtersContainer {
  display: flex;

  // Meridian overrides
  fieldset {
    max-height: 400px;
    padding: 0 10px;

    overflow: hidden;
    overflow-y: scroll;
  }

  fieldset:not(:first-of-type) {
    margin-left: 10px;
  }
}

.list {
  list-style: none;
  padding: 0;
  margin: 0;
}