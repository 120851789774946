.refreshContainer {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.refreshPlacementsMessage {
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 16px;
  color: gray;
}

.tableHeader {
  display: flex;
  align-items: self-start;
}

.tableHeaderLabel {
  margin-right: 5px;
}