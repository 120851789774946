@import '../../../src/styles/sitewide.module';

$containerWidth: 90vw;
$spaceFromNeighbour: 10px;
$cardListContainerSpaceFromTitle: $spaceFromNeighbour;
$paginationSpaceFromContent: $spaceFromNeighbour;

$buttonAddNewSpaceFromNeighbour: $spaceFromNeighbour;

.container {
  @include customScrollBar;
  width: $containerWidth;

  margin: 0 auto;

  // Meridian's overwrite to prevent data overflow in tables
  td {
    word-break: break-all;
  }
}

.headerContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-top: $spaceFromNeighbour;
}

.headerMain {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.configSelectorTitle {
  display: flex;
  margin-bottom: 5px;

  font-size: 12px;
  color: darkgray;
}

.buttonAddNew {
  margin-left: $buttonAddNewSpaceFromNeighbour;
}

.cardListContainer {
  margin-top: $cardListContainerSpaceFromTitle;

  overflow-y: scroll;
}

.pagination {
  margin-top: $paginationSpaceFromContent;
}