@import '../../../src/styles/sitewide.module';

$padding: 8px;
$spaceFromContentUploaderSection: 20px;
$spaceFromSectionTitle: 10px;

.spacer {
  text-align: center;
  margin: 8px 0;
}
.notice {
  text-align: center;
  margin: 12px;
  background-color: #eee;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 12px;
}
.noticeWrapper {
  padding-top: 44px;
}
.actions {
  display: inline-flex;
  margin: 16px; 
}
.button {
  margin: 0 4px;
}
.dragArea {
  background-color: #eee;
  margin: $padding;
  padding: $padding;
  border: 1px solid #ccc;
  border-radius: 4px;
  &.dragOver {
    background-color: #e0e0ee;
  }
}
.messaging {
  margin: 8px;
}
.linkBar {
  position: fixed;
  background-color: #fff;
  z-index: 10;
  width: 100%;
  padding: 4px 8px;
}
.tabLink {
  color: black;
  &:visited {
    color: black;
  }
}

.validationControls {
  margin-top: $spaceFromContentUploaderSection;
}

.validationModesList {
  @include reset-list-props;

  margin-top: $spaceFromSectionTitle;
}