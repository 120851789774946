$paginatorHeight: 40px;
$padding: 8px;
$spaceFromTop: 66px;
$announcementContainerHeight: 40px;
$spaceFromTopWithAnnouncement: $spaceFromTop + $announcementContainerHeight;

.page {
  position: fixed;
  top: 0;
  bottom: $paginatorHeight + ($padding * 2);
  left: 0;
  right: 0;
  overflow: scroll;

  margin-top: $spaceFromTop;
}

.withAnnouncements {
  margin-top: $spaceFromTopWithAnnouncement;
}

.bottomActionBar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: $paginatorHeight;
  padding: 8px;
}

:export {
  spaceFromTop: $spaceFromTop;
}