@import '../../../../src/styles/sitewide.module';

$groupContainerSpaceFromNeighbour: 10px;
$groupContainerBorderRadius: 10px;
$groupContainerSpaceAround: 10px;
$groupContainerBackground: #f9f9f9;

$groupTitleSpaceFromContent: 3px;
$groupTitleDecorativeUnderlineSize: 2px;
$groupTitleFontSize: 16px;

$subTotalBlockFontSize: 13px;
$subTotalBlockFontColor: gray;

.groupContainer {
  margin-bottom: $groupContainerSpaceFromNeighbour;
  border-radius: $groupContainerBorderRadius;
  padding: $groupContainerSpaceAround;

  background: $groupContainerBackground;
}

.groupTitle {
  @include reset-box-model-props;

  display: inline-block;
  padding-bottom: $groupTitleSpaceFromContent;
  border: transparent;
  border-bottom: $groupTitleDecorativeUnderlineSize dashed;

  background-color: transparent;
  cursor: pointer;
  font-weight: bold;
  font-size: $groupTitleFontSize;
  outline: none;
}

.subTotalBlock {
  font-weight: bold;
  font-size: $subTotalBlockFontSize;
  color: $subTotalBlockFontColor;

  a {
    font-size: $subTotalBlockFontSize;
  }
}

.list {
  @include reset-box-model-props;

  list-style: none;

  &.closed > li {
    display: none;
  }
}
