@import '../../../../src/styles/sitewide.module';

$bufferSpaceForSettingsButton: 70px;

.container {
  position: relative;

  display: flex;
  flex-direction: column;

  padding: 10px;
  padding-right: $bufferSpaceForSettingsButton;
  border-radius: 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

  background: white;
}

.content {
  display: flex;

  & .actionButtons {
    display: flex;
    justify-content: space-between;
    margin-left: 10px;

    button:not(:first-of-type) {
      margin-left: 5px;
    }
  }
}

.dateTimeControls {
  display: flex;
  flex-direction: column;
}

.timePicker {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;

  .startDate,
  .endDate {
    flex-basis: 49%;
    border-radius: 10px;

    /* Override for default GridView's time input style */
    & input {
      border-radius: 5px;
      border-color: gray;
    }
  }
}

.slotsSettingsButtonContainer {
  position: absolute;
  top: 10px;
  right: 10px;
}

.list {
  @include reset-list-props;

  &:not(:first-of-type) {
    margin-top: 5px;
  }
}

.listItem:not(:first-of-type) {
  margin-top: 5px;
}