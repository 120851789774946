@import '../../../src/styles/sitewide.module';

$overlayContainerIndex: 999;
$overlayContainerMinHeight: 40px;

$notificationsContainerMaxHeight: 50vh;

$notificationContainerFontSize: 14px;
$notificationContainerSpaceAround: 10px;
$notificationContainerBottomBorder: 1px solid rgba(255, 255, 255, 0.2);
$notificationContainerHoveredOverColor: rgba(4, 128, 171, 1);

$messageContainerWidth: 95%;
$messageContainerSpaceFromCloseButton: 20px;

$announcementSpaceFromNextElement: 5px;

$closeButtonSpaceFromRight: 5px;
$closeButtonWidth: 40px;
$closeButtonHeight: 30px;
$closeButtonFontSize: 20px;
$closeButtonFontSizeHover: 30px;
$closeButtonFontWeight: 700;
$closeButtonOpacity: 0.5;
$closeButtonOpacityHover: 0.75;

$backgroundColor: rgba(4, 128, 171, 0.9);
$textColor: rgba(255, 255, 255, 1);
$textShadowColor: $textColor;

.container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: $overlayContainerIndex;

  min-height: $overlayContainerMinHeight;

  color: $textColor;
  background: $backgroundColor;
}

.notifications {
  @include reset-box-model-props;

  max-height: $notificationsContainerMaxHeight;

  overflow: scroll;
}

.notificationContainer {
  padding: $notificationContainerSpaceAround;
  border-bottom: $notificationContainerBottomBorder;

  font-size: $notificationContainerFontSize;

  &:hover {
    background-color: $notificationContainerHoveredOverColor;
  }
}

.messageContainer {
  @include reset-box-model-props;

  padding-right: $messageContainerSpaceFromCloseButton;
}

.closeButton {
  position: absolute;
  top: 0;
  right: $closeButtonSpaceFromRight;

  width: $closeButtonWidth;
  height: $closeButtonHeight;
  padding: 0;
  background-color: transparent;
  border: 0;
  border-radius: 0;
  -webkit-appearance: none;

  float: right;

  font-size: $closeButtonFontSize;
  font-weight: $closeButtonFontWeight;
  line-height: 1;

  text-shadow: 0 1px 0 $textShadowColor;
  opacity: $closeButtonOpacity;

  color: inherit;
  outline: none;
  cursor: pointer;
}

.closeButton:focus,
.closeButton:hover {
  font-size: $closeButtonFontSizeHover;
  color: $textColor;
  text-decoration: none;
  opacity: $closeButtonOpacityHover;
}

.announcement {
  @include reset-box-model-props;

  p,
  h1,
  h2,
  h3
  {
    @include reset-box-model-props;

    margin-bottom: $announcementSpaceFromNextElement;
  }
}

.readMoreLink {
  @include reset-box-model-props;

  margin-top: 5px;

  a {
    color: inherit;
    font-size: 12px;
  }
}