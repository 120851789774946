@import '../../../../src/styles/sitewide.module';

$cardBasisWidth: 47%;
$cardMinHeight: 200px;
$cardMaxHeight: $cardMinHeight * 3;
$cardSpaceAroundText: 0 10px;
$cardSpaceFromBottomNeighbour: 7px;
$cardBorderRadius: 10px;
$cardBorderSize: 20px;
$cardBgColor: #ffffff;
$cardShadow: rgba(0,0,0,0.11) 0 0.3px 0.9px, rgba(0,0,0,0.11) 0 1.6px 3.6px;

$spaceFromContent: 10px;

.heading {
  margin-bottom: $spaceFromContent;
}

.card {
  flex-basis: $cardBasisWidth;
  min-height: $cardMinHeight;
  max-height: $cardMaxHeight;
  padding: $cardSpaceAroundText;
  margin-bottom: $cardSpaceFromBottomNeighbour;
  border-radius: $cardBorderRadius;
  border-bottom: $cardBorderSize solid white;
  border-top: $cardBorderSize solid white;

  background-color: $cardBgColor;

  box-shadow: $cardShadow;
  overflow: scroll;
}

.disabled {
  filter: opacity(0.5);
}

.cardHeader {
  @include reset-box-model-props;
}
