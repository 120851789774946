.even {
  background-color: #eee;
}
.odd {
  background-color: #e9e9ee;
}
.highlight {
  background-color: #72daff;
}

.expandButton {
  display: flex;
}

/*
  Transform popover window look (for the last 5 rows) to be displayed upside down
  (as it might overflow and go beyond the view port in case there is a lot of text inside).
 */
.row:nth-last-child(-n+5) {

  :global(div[class*="popOver"]) {
    top: auto;
    bottom: 100%;
  }
}