@import '../../../src/styles/sitewide.module';

$genericBoxShadow: 5px 11px 27px -10px rgba(0,0,0,0.73);
$genericBorderRadius: 10px;
$genericBgColor: #ffffff;

$buttonMinWidth: 60px;

$loadingContainerSpaceFromHeader: 20px;

$containerVerticalSpacing: 20px;

$wrapperWidth: 600px;

$innerContainerBorderRadius: $genericBorderRadius;
$innerContainerHorizontalSpaceAround: 10px;
$innerContainerBoxShadow: $genericBoxShadow;
$innerContainerHorizontalCollapseSpace: 1px;
$innerContainerBgColor: $genericBgColor;

$headingHorizontalSpaceAround: 10px;
$headingFontSize: 28px;

$buttonsWrapperSpaceFromTop: 5px;
$buttonWrapperMinWidth: 100px;

$listFontSize: 14px;

$widgetContainerZIndex: 10;
$widgetContainerSpaceFromNavBar: 55px;
$widgetContainerSpaceFromViewPortRight: 1vw;
$widgetWrapperWidth: 500px;
$widgetListMaxHeight: 70vh;
$widgetListVerticalSpaceAround: 10px;
$widgetHeadingFontSize: 20px;

$paginationSpaceFromContent: 15px;
$paginationBorderRadius: 10px;
$paginationHorizontalSpaceAround: 10px;

.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;

  margin-top: $loadingContainerSpaceFromHeader;
}

.container {
  display: flex;
  justify-content: center;

  margin: $containerVerticalSpacing 0;
}

.wrapper {
  width: $wrapperWidth;
}

.innerContainer {
  position: relative;

  border-radius: $innerContainerBorderRadius;
  padding: 0 $innerContainerHorizontalSpaceAround;
  box-shadow: $innerContainerBoxShadow;
  /* Prevents .innerContainer and .wrapper from collapsing on vertical margin */
  border-top: transparent $innerContainerHorizontalCollapseSpace solid;
  border-bottom: transparent $innerContainerHorizontalCollapseSpace solid;

  background: $innerContainerBgColor;
}

.buttonsWrapper {
  position: absolute;
  right: 0;
  top: $buttonsWrapperSpaceFromTop;
}

.buttonWrapper {
  min-width: $buttonWrapperMinWidth;
}

.buttonRefresh,
.closeButton {
  min-width: $buttonMinWidth;
}

.heading {
  display: flex;
  justify-content: center;

  margin: $headingHorizontalSpaceAround 0;

  font-size: $headingFontSize;
}

.list {
  list-style: none;
  word-wrap: break-word;

  font-size: $listFontSize;

  @include reset-box-model-props;
}

.noNotifications {
  display: flex;
  justify-content: center;
}

.pagination {
  display: flex;
  justify-content: center;

  margin-top: $paginationSpaceFromContent;
  border-radius: $paginationBorderRadius;
  padding: 0 $paginationHorizontalSpaceAround;
  box-shadow: $genericBoxShadow;
  background: $genericBgColor;
}

.widgetMode.container {
  position: absolute;
  z-index: $widgetContainerZIndex;
  top: $widgetContainerSpaceFromNavBar;
  right: $widgetContainerSpaceFromViewPortRight;

  margin: auto;
}

.widgetMode {
  .buttonsWrapper {
    top: initial;
  }

  .wrapper {
    width: $widgetWrapperWidth;
  }

  .list {
    max-height: $widgetListMaxHeight;
    margin: $widgetListVerticalSpaceAround 0;

    overflow: scroll;

    @include hideScrollbar;
  }

  .heading {
    font-size: $widgetHeadingFontSize;
  }

  .seeAllNotifications {
    display: flex;
    justify-content: center;

    padding: 10px;
    border-top: 1px solid lightgray;
  }
}

.actionButtonsContainer {
  display: flex;
  justify-content: space-between;

  padding: 10px 0;
}
