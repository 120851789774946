.multiTextContainer {
  display: block;
  word-break: break-all;
}

.itemContainer {
  position: relative;
}

.floatingLink {
  position: absolute;
  right: 1px;
  top: 1px;
  padding: 2px;
  z-index: 10;
}

