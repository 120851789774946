// Meridian's header override
// It doesn't let absolutely positioned elements to be displayed otherwise
:global(nav) {
  overflow: initial !important;
  padding: 5px 0;
}

.linkContainer {
  display: flex;
}

.link {
  margin-left: 5px;
}

.logoContainer {
  display: flex;
  justify-content: center;
  align-items: center;

  .logoText {
    margin-left: 5px;
  }

  .environmentTag {
    margin-left: 5px;
  }
}
